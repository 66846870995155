import React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

export default function Projects() {
  return (
    <Layout pageTitle="Torie Jee | Projects" mainClassName="pb-5">
      <Container as="section">
        <header>
          <h2 className="text-center">Twentieth Century Studios</h2>
          <p>
            Below are key titles I worked on the UHD/Blu-Ray/DVD and Apple
            iTunes releases for and/or developed and oversaw the production of
            their behind-the-scenes special feature documentaries.
          </p>
        </header>
        <Row as="ul" xs={1} md={2} xl={3}>
          <Col as="li">
            <cite>Youth</cite>
          </Col>
          <Col as="li">
            <cite>Brooklyn</cite>
          </Col>
          <Col as="li">
            <cite>Peanuts</cite>
          </Col>
          <Col as="li">
            <cite>Victor Frankenstein</cite>
          </Col>
          <Col as="li">
            <cite>Other Side of the Door</cite>
          </Col>
          <Col as="li">
            <cite>Martian Extended</cite>
          </Col>
          <Col as="li">
            <cite>Joy</cite>
          </Col>
          <Col as="li">
            <cite>The Revenant</cite>
          </Col>
          <Col as="li">
            <cite>Eddie the Eagle</cite>
          </Col>
          <Col as="li">
            <cite>Dance Camp</cite>
          </Col>
          <Col as="li">
            <cite>Assassin's Creed</cite>
          </Col>
          <Col as="li">
            <cite>El Clan </cite>
          </Col>
          <Col as="li">
            <cite>Demolition</cite>
          </Col>
          <Col as="li">
            <cite>A Bigger Splash</cite>
          </Col>
          <Col as="li">
            <cite>Kung Fu Panda 3</cite>
          </Col>
          <Col as="li">
            <cite>Absolutely Fabulous</cite>
          </Col>
          <Col as="li">
            <cite>Mike and Dave Need Wedding Dates</cite>
          </Col>
          <Col as="li">
            <cite>A Cure for Wellness</cite>
          </Col>
          <Col as="li">
            <cite>Gifted</cite>
          </Col>
          <Col as="li">
            <cite>Keeping up with the Joneses</cite>
          </Col>
          <Col as="li">
            <cite>Miss Peregrine's Home for Peculiar Children</cite>
          </Col>
          <Col as="li">
            <cite>Rico Oskar und der Diebstahlstein</cite>
          </Col>
          <Col as="li">
            <cite>Wilson</cite>
          </Col>
          <Col as="li">
            <cite>Absolutely Fabulous</cite>
          </Col>
          <Col as="li">
            <cite>Morgan</cite>
          </Col>
          <Col as="li">
            <cite>Birth of a Nation</cite>
          </Col>
          <Col as="li">
            <cite>Rules Don't Apply</cite>
          </Col>
          <Col as="li">
            <cite>Jackie</cite>
          </Col>
          <Col as="li">
            <cite>Assassin's Creed</cite>
          </Col>
          <Col as="li">
            <cite>Red Sparrow</cite>
          </Col>
          <Col as="li">
            <Link to="/campaigns#alita-battle-angel">
              <cite>Alita: Battle Angel</cite>
            </Link>
          </Col>
          <Col as="li">
            <cite>The Shape of Water</cite>
          </Col>
          <Col as="li">
            <cite>The Mountain Between Us</cite>
          </Col>
          <Col as="li">
            <cite>Antonio, ihm Schmeckt's Nict</cite>
          </Col>
          <Col as="li">
            <cite>Why Him?</cite>
          </Col>
          <Col as="li">
            <cite>Hidden Figures</cite>
          </Col>
          <Col as="li">
            <cite>Table 19</cite>
          </Col>
          <Col as="li">
            <cite>A United Kingdom</cite>
          </Col>
          <Col as="li">
            <cite>A Cure for Wellness</cite>
          </Col>
          <Col as="li">
            <cite>The Battle of the Sexes</cite>
          </Col>
          <Col as="li">
            <cite>Wilson</cite>
          </Col>
          <Col as="li">
            <cite>Gifted</cite>
          </Col>
          <Col as="li">
            <cite>Snatched</cite>
          </Col>
          <Col as="li">
            <cite>Free Guy</cite>
          </Col>
          <Col as="li">
            <cite>Maze Runner: The Death Cure</cite>
          </Col>
          <Col as="li">
            <cite>Ready or Not</cite>
          </Col>
          <Col as="li">
            <cite>The Predator</cite>
          </Col>
          <Col as="li">
            The UHD release of <cite>Predator</cite> 1-3
          </Col>
        </Row>
      </Container>
      <Container as="section" className="mt-5">
        <header>
          <h2 className="text-center">
            The Walt Disney Studios, Twentieth Century Studios, and Searchlight
            Pictures at OMG23
          </h2>
          <p>
            Below are some of digital marketing campaign executions I led to
            promote the studios' theatrical and home entertainment releases.
            This involved developing custom page takeovers with publishers like
            Roku, IMDb, Amazon, and Google TV.
          </p>
        </header>
        <ul className="row row-cols-1 row-cols-lg-2">
          <Col as="li">
            Disney and Pixar's <cite>Lightyear</cite> - Theatrical Campaign
          </Col>
          <Col as="li">
            <Link to="/campaigns#rescue-rangers">
              <cite>Rescue Rangers</cite> - Disney+ Campaign
            </Link>
          </Col>
          <Col as="li">
            <cite>Rise</cite> - Disney+ Campaign
          </Col>
          <Col as="li">
            <Link to="/campaigns#turning-red">
              Disney and Pixar's <cite>Turning Red</cite> - Disney+ Campaign
            </Link>
          </Col>
          <Col as="li">
            <cite>Cheaper by the Dozen</cite> - Disney+ Campaign
          </Col>
          <Col as="li">
            <cite>The King's Man</cite> - Theatrical Campaign
          </Col>
          <Col as="li">
            <cite>Encanto</cite> - Theatrical Campaign
          </Col>
          <Col as="li">
            <cite>Marvel's Eternals</cite> - Theatrical Campaign
          </Col>
          <Col as="li">
            <cite>Shang Chi and the Legend of the Ten Rings</cite> - Theatrical
            Campaign
          </Col>
          <Col as="li">
            <cite>Jungle Cruise</cite> - Disney+ &amp; Theatrical Campaign
          </Col>
          <Col as="li">
            <Link to="/campaigns#luca">
              Disney and Pixar's <cite>Luca</cite> - Disney+ Campaign
            </Link>
          </Col>
          <Col as="li">
            <cite>Ford v Ferrari</cite> - Home Entertainment Campaign
          </Col>
          <Col as="li">
            <cite>The New Mutants</cite> - Home Entertainment Campaign
          </Col>
          <Col as="li">
            <cite>Star Wars: The Rise of Skywalker</cite> - Theatrical Boost
            Campaign
          </Col>
          <Col as="li">
            <cite>A Hidden Life</cite> - Theatrical Campaign
          </Col>
          <Col as="li">
            <cite>Wendy</cite> - Theatrical Campaign
          </Col>
        </ul>
      </Container>
      <Container as="section" className="mt-5">
        <header>
          <h2 className="text-center">ABC Entertainment at OMG23</h2>
          <p>
            Below are some of the digital marketing campaigns I oversaw for
            season premieres, series premieres, and series returns. This
            involved working with publishers/influencers to film or execute
            custom videos and custom page takeovers to garner excitement for ABC
            Entertainment shows.
          </p>
        </header>
        <Row as="ul" xs={1} md={2} xl={3}>
          <Col as="li">
            <cite>A Million Little things</cite>
          </Col>
          <Col as="li">
            <cite>Academy Awards 2021</cite>
          </Col>
          <Col as="li">
            <cite>American Housewife</cite>
          </Col>
          <Col as="li">
            <cite>Baker and the Beauty</cite>
          </Col>
          <Col as="li">
            <Link to="/campaigns#black-ish">
              <cite>black-ish</cite>
            </Link>
          </Col>
          <Col as="li">
            <cite>Jimmy Kimmel Live</cite>
          </Col>
          <Col as="li">
            <Link to="/campaigns#pooch-perfect">
              <cite>Pooch Perfect</cite>
            </Link>
          </Col>
          <Col as="li">
            <cite>The Rookie</cite>
          </Col>
          <Col as="li">
            <cite>Press Your Luck</cite>
          </Col>
          <Col as="li">
            <cite>Celebrity Family Feud</cite>
          </Col>
          <Col as="li">
            <cite>Match Game</cite>
          </Col>
          <Col as="li">
            <cite>Who Wants to Be a Millionaire</cite>
          </Col>
          <Col as="li">
            <cite>The Conners</cite>
          </Col>
          <Col as="li">
            <cite>The Good Doctor</cite>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
